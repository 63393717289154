import { createI18n } from 'vue-i18n'
import de from './i18n/de_DE.json'
import en from './i18n/en_US.json'
import fr from './i18n/fr_FR.json'
import at from './i18n/de_AT.json'
import lu from './i18n/fr_LU.json'

const i18n = createI18n({
    locale: 'de_DE',
    fallbackLocale: 'de_DE',
    messages: {
        de_DE: de,
        de_AT: at,
        fr_LU: lu,
        en_US: en,
        fr_FR: fr,
    },
    silentTranslationWarn: true,
    fallbackWarn: false,
    missingWarn: false,
    silentFallbackWarn: true,
    warnHtmlMessage: false,
    legacy: false,
})

export default i18n
