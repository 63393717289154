<template>
    <ul class="main-menu">
        <!-- Minified logo for scroll header -->
        <li class="menu-item show-if-sticky">
            <a
                href="/portal/mc"
                title="Zur Startseite"
                onclick="singleSpaNavigate(event)"
            >
                <img
                    src="@/assets/images/logo/without-claim/CR-Bildmarke-RGB.svg"
                    alt=""
                    width="26"
                    height="34"
                />
            </a>
        </li>

        <!-- Main menu items -->
        <template v-for="(item, i) in mainMenu">
            <li
                class="menu-item has-submenu"
                :key="'main-menu-with-subitems-' + i"
                v-if="showSubmenu(item)"
            >
                <a
                    :data-qa="item.dataQa"
                    href="javascript:void(0);"
                    @mouseenter="preload(item)"
                >
                    <span>{{ $t(item.translateKey) }}</span>
                </a>
                <div class="main-menu--slide">
                    <template v-for="(subitem, n) in item.subitems">
                        <span :key="n" v-if="subitem.show" class="submenu-item">
                            <span v-if="subitem.isRouterLink">
                                <a
                                    :href="subitem.href"
                                    onclick="singleSpaNavigate(event)"
                                    :data-qa="subitem.dataQa"
                                >
                                    <span>{{ $t(subitem.translateKey) }}</span>
                                </a>
                            </span>
                            <span v-else>
                                <a
                                    :href="subitem.href"
                                    :target="hrefTarget(subitem)"
                                    :data-qa="subitem.dataQa"
                                >
                                    <span>{{ $t(subitem.translateKey) }}</span>
                                </a>
                            </span>
                        </span>
                    </template>
                </div>
            </li>
            <li v-else-if="item.show" :key="'main-menu-' + i">
                <a :data-qa="item.dataQa" href="javascript:void(0);">
                    <span v-if="item.isRouterLink">
                        <a
                            :href="item.href"
                            onclick="singleSpaNavigate(event)"
                            :data-qa="item.dataQa"
                        >
                            <span>{{ $t(item.translateKey) }}</span>
                        </a>
                    </span>
                    <span v-else>
                        <a
                            :href="item.href"
                            :target="hrefTarget(item)"
                            :data-qa="item.dataQa"
                        >
                            <span>{{ $t(item.translateKey) }}</span>
                        </a>
                    </span>
                </a>
            </li>
        </template>
    </ul>
</template>

<script>
import { mapState } from 'vuex'
import {
    standardInkassoSubitems,
    getCustomInkassoLinksForClz,
} from '@/components/navigation/partials/inkasso-sub-menu-items'

export default {
    name: 'MainMenu',
    data() {
        return {
            inkassoSubitems: standardInkassoSubitems,
        }
    },
    computed: {
        ...mapState(['featureModule']),
        mainMenu() {
            return !this.isMitgliedsbenutzer
                ? this.mainMenuRegusers
                : this.mainMenuMembers
        },
        mainMenuMembers() {
            const getMahnwesenLink = () => {
                let subdomain = location.hostname
                    .replace('cp.', '.')
                    .replace('meine.creditreform.de', '')
                if (subdomain === '') {
                    subdomain = 'www.'
                } else if (subdomain === 'sunzinet.') {
                    subdomain = 'sedev.'
                }
                return `https://${subdomain}creditreform-mahnwesen.de/de`
            }
            return [
                {
                    translateKey: 'mainmenu.businessreports.title',
                    dataQa: 'tabCategory-6',
                    subitems: [
                        {
                            translateKey:
                                'mainmenu.businessreports.subitem.reportb2b',
                            dataQa: 'submenuItem-27',
                            show: true,
                            isRouterLink: true,
                            href: '/portal/mc/bonitaetsauskunft/bonitaet-einer-firma-pruefen',
                        },
                        {
                            translateKey:
                                'mainmenu.businessreports.subitem.reportb2c',
                            dataQa: 'submenuItem-201',
                            show: true,
                            isRouterLink: true,
                            href: '/portal/mc/bonitaetsauskunft/bonitaet-einer-privatperson-pruefen',
                        },
                    ],
                },
                {
                    translateKey: 'mainmenu.debtcollection.title',
                    dataQa: 'tabCategory-7',
                    preload: '@portal-microfrontend/inkasso',
                    subitems: this.inkassoSubitems,
                },
                {
                    translateKey: 'mainmenu.mycompany.title',
                    dataQa: 'tabCategory-mycompany',
                    subitems: [
                        {
                            translateKey:
                                'mainmenu.mycompany.subitem.mycompany',
                            dataQa: 'tabCategory-270',
                            show: true,
                            isRouterLink: true,
                            href: '/portal/meine-bonitaet',
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.ubo',
                            dataQa: 'submenuItem-ubo',
                            show: true,
                            isRouterLink: true,
                            href: '/portal/ubo/',
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.myEsg',
                            dataQa: 'submenuItem-myesg',
                            show: true,
                            isRouterLink: true,
                            href: '/portal/myesg/',
                        },
                        {
                            translateKey:
                                'mainmenu.mycompany.subitem.companydata',
                            dataQa: 'submenuItem-companydata',
                            show: this.userHasRole('CR-CP.UNTERNEHMENSDATEN'),
                            isRouterLink: true,
                            href: '/portal/mc/mein-profil/unternehmensdaten',
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.userdata',
                            dataQa: 'submenuItem-userdata',
                            show: this.userHasRole('CR-CP.MITGLIEDSDATEN'),
                            isRouterLink: true,
                            href: '/portal/mc/mein-profil/mitgliedsdaten',
                        },
                    ],
                },
                {
                    translateKey: 'mainmenu.services.title',
                    dataQa: 'tabCategory-services',
                    subitems: [
                        {
                            translateKey: 'mainmenu.services.subitem.mahnwesen',
                            dataQa: 'submenuItem-mahnwesen',
                            show: this.isNotAustrian && this.isNotLuxemburg,
                            isRouterLink: false,
                            isExternalLink: true,
                            href: getMahnwesenLink(),
                        },
                        {
                            translateKey: 'mainmenu.services.subitem.drd',
                            dataQa: 'submenuItem-drd',
                            show:
                                this.featureDrdIsActive && this.isNotLuxemburg,
                            isRouterLink: true,
                            href: '/portal/drd',
                        },
                        {
                            translateKey:
                                'mainmenu.services.subitem.crefoaddress',
                            dataQa: 'submenuItem-crefoaddress',
                            show: true,
                            isRouterLink: false,
                            isExternalLink: true,
                            href: 'https://crefoaddress.de',
                        },
                        {
                            translateKey: 'mainmenu.services.subitem.magazine',
                            dataQa: 'submenuItem-magazine',
                            show: true,
                            isRouterLink: true,
                            href: '/portal/creditreform-magazin',
                        },
                    ],
                },
                {
                    translateKey: 'mainmenu.auswertungen.title',
                    dataQa: 'tabCategory-auswertungen',
                    isRouterLink: true,
                    show: this.userHasCrMemberuserid() && !this.isActFor,
                    subitems: [],
                    href: '/portal/auskunftsexport',
                },
                {
                    translateKey: 'mainmenu.downloadbereich.title',
                    dataQa: 'tabCategory-downloadbereich',
                    show: !this.isActFor,
                    isRouterLink: true,
                    subitems: [],
                    href: '/portal/downloadbereich',
                },
            ]
        },
        mainMenuRegusers() {
            return [
                {
                    translateKey: 'mainmenu.mycompany.title',
                    dataQa: 'tabCategory-mycompany',
                    subitems: [
                        {
                            translateKey:
                                'mainmenu.mycompany.subitem.mycompany',
                            dataQa: 'tabCategory-270',
                            show: true,
                            isRouterLink: true,
                            href: '/portal/meine-bonitaet',
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.ubo',
                            dataQa: 'submenuItem-ubo',
                            show: true,
                            isRouterLink: true,
                            href: '/portal/ubo/',
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.myEsg',
                            dataQa: 'submenuItem-myesg',
                            show: true,
                            isRouterLink: true,
                            href: '/portal/myesg/',
                        },
                    ],
                },
                {
                    translateKey: 'mainmenu.services.title',
                    dataQa: 'tabCategory-services',
                    subitems: [
                        {
                            translateKey: 'mainmenu.services.subitem.drd',
                            dataQa: 'submenuItem-drd',
                            show: this.featureDrdIsActive,
                            isRouterLink: true,
                            href: '/portal/drd',
                        },
                    ],
                },
                {
                    translateKey: 'mainmenu.downloadbereich.title',
                    dataQa: 'tabCategory-downloadbereich',
                    show: !this.isActFor,
                    isRouterLink: true,
                    subitems: [],
                    href: '/portal/downloadbereich',
                },
            ]
        },
        featureDrdIsActive() {
            return this.$store.state.featureModule.drd.active
        },
        isNotAustrian() {
            return this.$i18n.locale !== 'de_AT'
        },
        isNotLuxemburg() {
            return this.$i18n.locale !== 'fr_LU'
        },
        isMitgliedsbenutzer() {
            return this.$store.state.userModule.user.user.isMitgliedsbenutzer
        },
        isActFor() {
            return this.$store.state.userModule.user.user.actForMemberId
        },
    },
    methods: {
        showSubmenu(item) {
            if (!item.subitems.length) {
                return false
            }

            let i
            for (i = 0; i < item.subitems.length; i++) {
                if (item.subitems[i].show) {
                    return true
                }
            }

            return false
        },
        userHasRole(roleName) {
            return this.$store.state.userModule.user.user.user_roles.includes(
                roleName,
            )
        },
        userHasCrMemberuserid() {
            return !!this.$store.state.userModule.user.user.crMemberuserid
        },
        hrefTarget(el) {
            return el.isExternalLink ? '_blank' : ''
        },
        preload(item) {
            if (item.preload) {
                window.System.import(item.preload)
            }
        },
    },
    mounted() {
        const userClz = this.$store.state.userModule.user.user.clz
        if (userClz !== null) {
            getCustomInkassoLinksForClz(userClz).then((subitems) => {
                this.inkassoSubitems = subitems
            })
        } else {
            this.$store.watch(
                (state) => state.userModule.user.user.clz,
                (newClz, _oldClz) => {
                    if (newClz) {
                        getCustomInkassoLinksForClz(newClz).then((subitems) => {
                            this.inkassoSubitems = subitems
                        })
                    }
                },
            )
        }
    },
}
</script>

<style scoped lang="less">
.header-nav ul.main-menu a {
    &.router-link-exact-active {
        color: #004884;
    }
}
.header-nav .main-menu .has-submenu .main-menu--slide a {
    width: 100%;
}
.header-nav .main-menu .has-submenu.active .main-menu--slide {
    width: 320px;
}
.header-nav
    .main-menu
    .has-submenu.active
    .main-menu--slide
    > span:not(:last-child) {
    margin-bottom: 15px;
    display: block;
}
</style>
