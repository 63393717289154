<template>
    <a
        href="/portal/mc"
        onclick="singleSpaNavigate(event)"
        class="logo"
        data-qa="logo-desktop"
        title="Zur Startseite"
    >
        <picture>
            <source
                srcset="@/assets/images/logo/without-claim/CR-Bildmarke-RGB.svg"
                width="26"
                height="34"
                media="(max-width: 767px)"
            />
            <source srcset="@/assets/images/logo/with-claim/meine-CR-RGB.svg" />
            <img
                src="@/assets/images/logo/with-claim/meine-CR-RGB.svg"
                alt="Creditreform"
            />
        </picture>
    </a>
</template>

<style scoped lang="less">
@media only screen and (max-width: 767px) {
    .header-nav a.logo {
        left: 15px;
    }
}
@media only screen and (min-width: 768px) {
    .header-nav a.logo {
        margin-left: 0;
        width: 175px;
        left: -15px;
    }
}
@media only screen and (min-width: 1200px) {
    .header-nav a.logo {
        margin-left: 0;
        width: 250px;
        left: -28px;
    }
}
</style>
