import { h, createApp } from 'vue'

import singleSpaVue from 'single-spa-vue'

import App from '@/App.vue'

import DataReady from './plugins/data-ready'
import CrefoUI from './plugins/crefo-ui'
import { MockWebtrekkForUserCentrics } from '@/helper/webtrekk'
import store from './store'
import i18n from './i18n'

const vueLifecycles = singleSpaVue({
    createApp,
    appOptions: {
        render() {
            return h(App)
        },
    },
    handleInstance: (app) => {
        app.use(store)
        app.use(CrefoUI)
        app.use(DataReady)
        app.use(i18n)

        MockWebtrekkForUserCentrics()
    },
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
