import axios from 'axios'

axios.defaults.headers = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
}

/**
 * Before any request, we will check the lifetime of the Keycloak token.
 * If it's already expired or expiring soon (within the next 10 seconds),
 * we will force a token update before sending the request.
 */
axios.interceptors.request.use(
    async (config) => {
        console.log('AXIOS Interceptor', config)
        if (window.keycloak) {
            let tokenExpires = Math.round(
                window.keycloak.tokenParsed.exp +
                    window.keycloak.timeSkew -
                    new Date().getTime() / 1000,
            )
            if (tokenExpires < 10) {
                await window.updateKeycloakToken()
            }
            config.headers.Authorization = `Bearer ${window.keycloak.token}`
        }

        if (config.url[0] !== '/') {
            // Remove custom headers for CORS preflight requests, because some request header fields
            // are not allowed by Access-Control-Allow-Headers in preflight response.
            delete config.headers['Pragma']
            delete config.headers['Cache-Control']
            delete config.headers['Expires']
        }

        if (
            sessionStorage.getItem('actForMemberId') !== null &&
            sessionStorage.getItem('skipActForMemberHeader') === null // required for initial member data fetch
        ) {
            config.headers['X-Act-For-Member-Id'] =
                sessionStorage.getItem('actForMemberId')
        } else {
            delete config.headers['X-Act-For-Member-Id']
        }

        for (let i in config.headers) {
            let lengthValue = new TextEncoder().encode(config.headers[i]).length
            let lengthProp = new TextEncoder().encode(i).length
            console.log(
                'Axios HTTP Header: ',
                i,
                lengthProp,
                config.headers[i],
                lengthValue,
                lengthProp + lengthValue,
            )
        }
        return config
    },
    (error) => {
        Promise.reject(error)
    },
)

/**
 * Sends a GET request to receive basic business data for the logged-in user
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getCbraUserMemberdata = () => axios.get(`/cbra/members`)

/**
 * Sends a GET request to receive CBRA user data for the logged-in user
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getCbraUserdata = () => axios.get(`/cbra/user`)

/**
 * Sends a GET request to receive the member structure for the logged-in user
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getMemberStructure = () =>
    axios.get(`/cbra/memberstructure?navigationSelected=true`)

/**
 * Sends a GET request to receive number of watchlist entries for the logged-in user
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getWatchlistCount = () =>
    axios.get(
        `/cbra/watchlistentries/?pageSize=1&pageStartIndex=0&sort=EVENT_TIMESTAMP_DESC`,
    )

/**
 * Sends a GET request to receive unread message count for the logged-in user
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getUnreadMessagesCount = (memberId) =>
    axios.get(`/api/posteingang/mitgliedsstruktur/unreadCount/${memberId}`)

/**
 * Sends a GET request to receive VC contact data for the logged-in user
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getVCContact = () => axios.get(`/cbra/vc/forUser`)

/**
 * Sends a GET request to receive DRD feature toggle status
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getFeatureDrd = () => axios.get(`/api/drd/feature-switch`)

/**
 * Sends a GET request to receive all monorepo features
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getFeaturesMonorepo = () => axios.get(`/cbra/features`)

/**
 * Send a GET request to request member data.
 *
 * @return {Promise<AxiosResponse<any>>}
 */
let memberInfoReq = null
export const useNewInkassoFrontend = () => {
    const url = '/api/inkasso/inkassoMemberInfo'

    if (memberInfoReq) {
        return memberInfoReq
    }
    memberInfoReq = axios
        .get(url)
        .then((response) => {
            // nur wenns definitiv CTI-Inkasso ist, geht's weiter nach CTI, sonst immer ikaros-inkasso
            return !(
                200 === response.status &&
                response.data &&
                response.data.inkassoType === 'CTI'
            )
        })
        .catch((reason) => {
            console.error(
                'Could not check if actual user is Ikaros-member: ',
                reason,
            )
            return true // wenn Fehler dann ikaros-inkasso
        })
        .finally(() => {
            memberInfoReq = null
        })
    return memberInfoReq
}
