<template>
    <nav
        v-if="isAuthenticated && isMitgliedsbenutzer"
        class="sidebar-navigation"
    >
        <ul class="sidebar-navigation__list">
            <template v-for="item in navItems">
                <li
                    class="sidebar-navigation__item"
                    v-if="!item.requireSuperUser || isSuperUser"
                    :key="item.icon"
                >
                    <a
                        :data-qa="item.data.qa"
                        class="sidebar-navigation__link"
                        :href="item.data.href"
                        onclick="singleSpaNavigate(event)"
                        @click="checkIfPosteingang(item)"
                    >
                        <CrefoSidebarNavigationLinkInner
                            :item="item"
                            :unread-messages-count="unreadMessagesCount"
                            :watchlist-count="watchlistCount"
                        />
                    </a>
                </li>
            </template>
        </ul>
    </nav>
</template>

<script>
import { mapState } from 'vuex'
import CrefoSidebarNavigationLinkInner from '@/components/navigation/partials/CrefoSidebarNavigationLinkInner.vue'
import { dispatchEvent } from '@/helper/polyfill'

export default {
    name: 'CrefoSidebarNavigation',
    components: {
        CrefoSidebarNavigationLinkInner,
    },
    data() {
        return {
            navItems: [
                {
                    icon: 'crefo-ui-icon icon-home',
                    data: {
                        title: 'featurenav.home',
                        qa: 'sideNavigation-1',
                        href: '/portal/mc/dashboard',
                    },
                },
                {
                    icon: 'crefo-brand-icon icon-envelope',
                    data: {
                        title: 'featurenav.inbox',
                        qa: 'sideNavigation-4',
                        href: '/portal/posteingang/',
                    },
                },
                {
                    icon: 'crefo-brand-icon icon-file-text',
                    data: {
                        title: 'featurenav.transactions',
                        qa: 'sideNavigation-5',
                        href: '/portal/mc/vorgaenge',
                    },
                },
                {
                    icon: 'crefo-ui-icon icon-watchlist',
                    data: {
                        title: 'featurenav.watchlist',
                        qa: 'sideNavigation-29',
                        href: '/portal/mc/bonitaetsauskunft/watchlist-unternehmen',
                    },
                },
                {
                    icon: 'crefo-ui-icon icon-users-settings',
                    requireSuperUser: true,
                    data: {
                        title: 'featurenav.memberadministration',
                        qa: 'sideNavigation-229',
                        href: '/portal/mc/nutzerverwaltung',
                    },
                },
            ],
        }
    },
    computed: {
        ...mapState(['userModule']),
        isSuperUser: function () {
            const roles = this.$store.state.userModule.user.user.user_roles
            for (const i in roles) {
                if (
                    roles[i].indexOf('CR-CP.BENUTZERVERWALTUNG-MITGLIED') !== -1
                ) {
                    return true
                }
            }

            return false
        },
        isAuthenticated: function () {
            return this.$store.state.userModule.user.authenticated
        },
        isMitgliedsbenutzer: function () {
            return this.$store.state.userModule.user.user.isMitgliedsbenutzer
        },
        memberId: function () {
            return this.$store.state.userModule.user.user.membernumber
        },
        watchlistCount: function () {
            return this.$store.state.navModule.nav.watchlistCount
        },
        unreadMessagesCount: function () {
            return this.$store.state.navModule.nav.unreadMessagesCount
        },
    },
    methods: {
        differentContextActive: function () {
            const originalMemberId = sessionStorage.getItem('originalMemberId')
            const actForMemberId = sessionStorage.getItem('actForMemberId')
            return (
                (originalMemberId &&
                    actForMemberId &&
                    originalMemberId !== actForMemberId) ||
                false
            )
        },
        checkIfPosteingang(item) {
            if (
                item.href === window.location.pathname &&
                item.data.title === 'featurenav.inbox'
            ) {
                dispatchEvent('reloadPosteingang')
            }
        },
        fetchData() {
            if (this.isMitgliedsbenutzer) {
                if (!this.differentContextActive()) {
                    this.$store.dispatch('navModule/setWatchlistCount')
                }
                this.$store.dispatch('navModule/setUnreadMessagesCount')
            }
        },
    },
    mounted() {
        this.$store.watch(
            (state) => state.userModule.user.authenticated,
            (newProps, oldProps) => {
                if (newProps) {
                    this.fetchData()
                }
            },
        )
        this.$store.watch(
            (state) => state.userModule.user.user.actForMemberId,
            (newProps, oldProps) => {
                this.fetchData()
            },
        )
    },
}
</script>

<style scoped>
.sidebar-navigation {
    position: fixed;
    top: 230px;
    left: 0;
    z-index: 10;
}
.sidebar-navigation__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.sidebar-navigation__item {
    position: relative;
    display: block;
    background-color: #009ee3;
    cursor: pointer;
    width: 80px;
    height: 80px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.125);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.125);
}
.sidebar-navigation__item + .sidebar-navigation__item {
    margin-top: 4px;
}
.sidebar-navigation__item--selected {
    background-color: #004884;
}
.sidebar-navigation__item:hover {
    background-color: #004884;
}

.sidebar-navigation__link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 80px;
    height: 80px;
    position: relative;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    flex-direction: column;
}

@media only screen and (max-width: 767px) {
    .sidebar-navigation {
        display: none !important;
    }
}
</style>
