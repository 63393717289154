const logoutConfigurations = [
    { match: '/portal/meine-bonitaet', logoutUrl: '/portal/meine-bonitaet' },
    { match: '/portal/ubo', logoutUrl: '/portal/ubo' },
    { match: '/portal/lksg', logoutUrl: '/portal/lksg' },
    { match: '/portal/myesg', logoutUrl: '/portal/myesg' },
]

export function getLogoutUrl() {
    const origin = `${location.protocol}//${location.host}`
    const logoutConfig = logoutConfigurations.find(
        (config) => location.href.indexOf(config.match) !== -1,
    )
    if (logoutConfig) {
        return `${origin}${logoutConfig.logoutUrl}`
    } else {
        return origin
    }
}
