export function USER_AUTHENTICATED(state, data) {
    state.user.authenticated = true
    state.user.token = data.token
    state.user.user.givenName = data.idTokenParsed.given_name
    state.user.user.surname = data.idTokenParsed.family_name
    state.user.user.email = data.idTokenParsed.email
    state.user.user.user_roles = data.idTokenParsed.cr_userrole
    state.user.user.id = data.idTokenParsed.cr_id
    state.user.user.membernumber = data.idTokenParsed.cr_membernumber
    state.user.user.isMitgliedsbenutzer =
        data.tokenParsed.cr_mapping && data.tokenParsed.cr_mapping.length
            ? true
            : false
    state.user.user.crMemberuserid = data.tokenParsed.cr_memberuserid || null
}

export function USER_TOKEN_UPDATED(state, data) {
    state.user.authenticated = true
    state.user.token = data.token
    state.user.user.givenName = data.idTokenParsed.given_name
    state.user.user.surname = data.idTokenParsed.family_name
    state.user.user.email = data.idTokenParsed.email
    state.user.user.user_roles = data.idTokenParsed.cr_userrole
    state.user.user.id = data.idTokenParsed.cr_id
    state.user.user.membernumber = data.idTokenParsed.cr_membernumber
    state.user.user.isMitgliedsbenutzer =
        data.tokenParsed.cr_mapping && data.tokenParsed.cr_mapping.length
            ? true
            : false
    state.user.user.crMemberuserid = data.tokenParsed.cr_memberuserid || null
}

export function SET_ACT_FOR_MEMBER(state, data) {
    state.user.user.actForMemberId = data.actForMemberId
    state.user.user.actForMemberName = data.actForMemberName
    state.user.user.reportAct4SbNumber = data.reportAct4SbNumber
}

export function SET_MEMBER_NAME_AND_ID(state, data) {
    state.user.user.memberId = data.memberId
    state.user.user.memberName = data.memberName
}

export function RECEIVE_MEMBER_DATA_SUCCESS(state, data) {
    state.user.user.memberId = data.memberId
    state.user.user.memberName = data.memberName
}

export function RECEIVE_MEMBER_DATA_ERROR(state) {
    state.user.user.memberId = null
    state.user.user.memberName = ''
}

export function RECEIVE_USER_DATA_SUCCESS(state, data) {
    state.user.user.clz = data.clz
}

export function RECEIVE_USER_DATA_ERROR(state) {
    state.user.user.clz = null
}

export function RECEIVE_MEMBER_STRUCTURE_SUCCESS(state, data) {
    state.user.user.memberStructure = data
}

export function RECEIVE_MEMBER_STRUCTURE_ERROR(state) {
    state.user.user.memberStructure = null
    state.user.user.actForMemberId = null
    state.user.user.actForMemberName = ''
}

export function SET_LANGUAGE(state, data) {
    state.user.clientLang = data
}

export function LOGOUT(state) {
    state.user = {
        authenticated: false,
        token: null,
        clientLang: 'de_DE',
        user: {
            salutationCode: null,
            title: null,
            genderCode: null,
            birthdate: null,
            department: null,
            position: null,
            telephoneNumber: null,
            preferredLanguage: null,
            givenName: '',
            surname: '',
            email: null,
            password: null,
            company: {
                name: '',
                crefonummer: null,
            },
            memberId: null,
            memberName: '',
            membernumber: null,
            id: null,
            user_roles: [],
            memberStructure: null,
            actForMemberId: null,
            actForMemberName: '',
        },
    }
}
