<template>
    <div class="profile-menu" :class="{ active: isOpen }">
        <a
            v-if="!isAuthenticated"
            data-qa="portal-base-login"
            @click.prevent="login"
            href="javascript:void(0);"
            class="login-link"
        >
            <span>{{ $t('mainmenu.login') }}</span>
            <i
                class="crefo-ui-icon icon-enter icon-color-blue"
                aria-hidden="true"
            ></i>
        </a>
        <a
            v-else
            class="profile-menu-opener"
            data-qa="profile-menu-opener"
            @click.prevent="toggleProfileMenu"
            href="javascript:void(0);"
        >
            <span class="company-logo">
                <i
                    class="crefo-brand-icon icon-building icon-color-blue"
                    aria-hidden="true"
                ></i>
            </span>
            <span
                class="company-name"
                data-qa="profile-menu-opener-member-name"
                >{{ memberName }}</span
            >
            <span class="open-close">
                <i
                    class="crefo-ui-icon icon-chevron-down"
                    aria-hidden="true"
                ></i>
            </span>
        </a>
        <div class="profile-menu--slide">
            <div class="company" v-if="memberNumber">
                <div>
                    <span
                        class="company-name text-brand-darkest-blue text-bold"
                    >
                        {{ memberName }} </span
                    ><br />
                    <span class="company-crefonumber text-brand-grey">
                        {{ $t('crefonumber.abbrev') }}.: {{ memberNumber }}
                    </span>

                    <div
                        v-if="memberstructureNavigationAllowed"
                        class="memberstructure-navigation"
                        :class="{ active: memberstructureNavigationOpen }"
                    >
                        <button
                            data-qa="toggle-memberstructure"
                            class="btn btn-default btn-block btn-memberstructure"
                            @click="toggleMemberstructureNavigation"
                        >
                            <span>{{ $t('memberstructuremenu.button') }}</span>
                            <span class="open-close">
                                <i
                                    class="crefo-ui-icon icon-chevron-down"
                                    aria-hidden="true"
                                ></i>
                            </span>
                        </button>
                        <div
                            class="memberstructure-navigation--list"
                            v-bind="{
                                'data-qa': memberstructureNavigationOpen
                                    ? 'comboBox-container'
                                    : '',
                            }"
                        >
                            <input
                                type="text"
                                class="memberstructure-navigation--list--search"
                                v-model="memberstructureNavigationOptionsSearch"
                                data-no-crefo-ui="true"
                                data-qa="comboBox-search-input"
                                :placeholder="
                                    memberstructureNavigationSearchPlaceholder
                                "
                            />
                            <div
                                class="memberstructure-navigation--list--results"
                                data-qa="comboBox-scroll-container"
                            >
                                <p
                                    v-for="(
                                        item, i
                                    ) in filteredMemberstructureNavigationOptions"
                                    :key="i"
                                    @click="
                                        setActForMember(
                                            item.memberId,
                                            item.displayName,
                                            true,
                                            item.reportAct4SbNumber,
                                        )
                                    "
                                    data-qa="comboBox-list-item"
                                >
                                    <span
                                        v-if="item.isMainMember"
                                        data-qa="comboBox-list-item-main-member"
                                        >{{
                                            $t(
                                                'memberstructuremenu.mainMember',
                                            )
                                        }}:</span
                                    >
                                    <br v-if="item.isMainMember" />
                                    <span
                                        class="text-bold"
                                        data-qa="comboBox-list-item-first-line"
                                        >{{ item.displayName }} ({{
                                            item.memberId
                                        }})</span
                                    ><br />
                                    <span
                                        class="text-small"
                                        data-qa="comboBox-list-item-second-line"
                                        >{{ item.address }}</span
                                    >
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        class="edit"
                        v-if="userHasRole('CR-CP.UNTERNEHMENSDATEN')"
                    >
                        <a
                            data-qa="profile-menu-edit-businessdata"
                            href="/portal/mc/mein-profil/unternehmensdaten"
                            onclick="singleSpaNavigate(event)"
                            @click.prevent="toggleProfileMenu"
                        >
                            <span v-if="!isActingForOtherMemberId">
                                {{ $t('profilemenu.companyprofile.edit') }}
                            </span>
                            <span v-else>
                                {{ $t('profilemenu.companyprofile.view') }}
                            </span>
                        </a>
                        <a
                            data-qa="profile-menu-edit-businessdata-icon"
                            class="edit"
                            href="/portal/mc/mein-profil/unternehmensdaten"
                            onclick="singleSpaNavigate(event)"
                            @click.prevent="toggleProfileMenu"
                        >
                            <i
                                v-if="!isActingForOtherMemberId"
                                class="crefo-ui-icon icon-edit-pen icon-color-blue"
                                aria-hidden="true"
                            ></i>
                            <i
                                v-else
                                class="crefo-ui-icon icon-eye-open icon-color-blue"
                                aria-hidden="true"
                            ></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="user">
                <div class="profile-picture-holder">
                    <i
                        class="profile-picture crefo-brand-icon icon-user-circle icon-color-blue"
                        aria-hidden="true"
                    ></i>
                    <div class="user-name">
                        <span
                            class="text-brand-darkest-blue text-bold text-ellipsis"
                            data-qa="profile-menu-user-name"
                        >
                            {{ username }}
                        </span>
                        <span>
                            <a
                                data-qa="profile-menu-edit-userdata"
                                :href="newSelfcareLink"
                                target="_blank"
                            >
                                {{ $t('profilemenu.userprofile.edit') }}
                            </a>
                            <a
                                data-qa="profile-menu-edit-userdata-icon"
                                class="edit"
                                :href="newSelfcareLink"
                                target="_blank"
                            >
                                <i
                                    class="crefo-ui-icon icon-edit-pen icon-color-blue"
                                    aria-hidden="true"
                                ></i>
                            </a>
                        </span>
                    </div>
                </div>
            </div>
            <div class="settings" v-if="isMitgliedsbenutzer">
                <div class="profile-picture-holder">
                    <i
                        class="profile-picture crefo-ui-icon icon-settings icon-color-blue"
                        aria-hidden="true"
                    ></i>
                    <div class="settings-link">
                        <span>
                            <a
                                data-qa="profile-menu-edit-settings"
                                href="/portal/mc/mein-profil"
                                onclick="singleSpaNavigate(event)"
                                @click.prevent="toggleProfileMenu"
                            >
                                {{ $t('profilemenu.settings.edit') }}
                            </a>
                            <a
                                data-qa="profile-menu-edit-settings-icon"
                                class="edit"
                                href="/portal/mc/mein-profil"
                                onclick="singleSpaNavigate(event)"
                                @click.prevent="toggleProfileMenu"
                            >
                                <i
                                    class="crefo-ui-icon icon-edit-pen icon-color-blue"
                                    aria-hidden="true"
                                ></i>
                            </a>
                        </span>
                    </div>
                </div>
            </div>
            <button
                data-qa="portal-base-logout"
                @click.prevent="logout"
                class="btn btn-default btn-block btn-logout"
            >
                <span>{{ $t('mainmenu.logout') }}</span>
            </button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { dispatchEvent } from '@/helper/polyfill'
import { KC_CONF, getUserManagementSelfcareUrl } from '../../../keycloak-config'
import Keycloak from 'keycloak-js'
import { clearAllStorages } from '@/helper/storage'
import { getLogoutUrl } from '@/router/index.js'

export default {
    name: 'ProfileMenu',
    data() {
        return {
            isOpen: false,
            newSelfcareLink: getUserManagementSelfcareUrl(),
            memberstructureNavigationAllowed: false,
            memberstructureNavigationOpen: false,
            memberstructureNavigationOptionsSearch: '',
            memberstructureNavigationOptions: [],
        }
    },
    computed: {
        ...mapState(['navModule', 'userModule']),
        isAuthenticated: function () {
            return this.$store.state.userModule.user.authenticated
        },
        isMitgliedsbenutzer: function () {
            return this.$store.state.userModule.user.user.isMitgliedsbenutzer
        },
        username: function () {
            return `${this.$store.state.userModule.user.user.givenName} ${this.$store.state.userModule.user.user.surname}`
        },
        memberName: function () {
            if (
                this.$store.state.userModule.user.user.actForMemberName.length
            ) {
                return this.$store.state.userModule.user.user.actForMemberName
            }
            if (this.$store.state.userModule.user.user.memberName.length) {
                return this.$store.state.userModule.user.user.memberName
            }
            return this.username
        },
        memberNumber: function () {
            return (
                this.$store.state.userModule.user.user.actForMemberId ||
                this.$store.state.userModule.user.user.memberId
            )
        },
        isActingForOtherMemberId: function () {
            return (
                this.$store.state.userModule.user.user.actForMemberId !== null
            )
        },
        memberstructureNavigationSearchPlaceholder: function () {
            return this.$t('memberstructuremenu.searchplaceholder')
        },
        filteredMemberstructureNavigationOptions: function () {
            let results = this.memberstructureNavigationOptions
            if (this.memberstructureNavigationOptionsSearch.length) {
                results = this.memberstructureNavigationOptions.filter(
                    (item) => {
                        return (
                            item.displayName
                                .toLowerCase()
                                .indexOf(
                                    this.memberstructureNavigationOptionsSearch.toLowerCase(),
                                ) !== -1 ||
                            item.address
                                .toLowerCase()
                                .indexOf(
                                    this.memberstructureNavigationOptionsSearch.toLowerCase(),
                                ) !== -1 ||
                            item.memberId
                                .toLowerCase()
                                .indexOf(
                                    this.memberstructureNavigationOptionsSearch.toLowerCase(),
                                ) !== -1
                        )
                    },
                )
            }

            return results
        },
    },
    methods: {
        initData() {
            this.getUserMemberdata()
            this.getMemberstructure()
        },
        backdropHandler(evt) {
            if (evt.target.classList.contains('backdrop-active')) {
                CrefoUI.Header.closeBackdropAndSubmenues() // ensure menus are closed
                this.$store.dispatch('navModule/closeAllNavMenus')
                document.body.classList.remove('backdrop-active')
                document.body.removeEventListener(
                    'click',
                    this.backdropHandler,
                    false,
                )
            }
        },
        removeBackdropAndClickHandler() {
            if (document.body.classList.contains('backdrop-active')) {
                document.body.classList.remove('backdrop-active')
            }
            document.body.removeEventListener(
                'click',
                this.backdropHandler,
                false,
            )
        },

        setActForMember(
            actForMemberId,
            actForMemberName,
            redirectOnChange = false,
            reportAct4SbNumber = null,
        ) {
            if (
                this.$store.state.userModule.user.user.membernumber ===
                actForMemberId
            ) {
                this.resetOriginalMember()
            } else {
                sessionStorage.setItem('actForMemberId', actForMemberId)
                sessionStorage.setItem('actForMemberName', actForMemberName)
                if (reportAct4SbNumber) {
                    sessionStorage.setItem(
                        'reportAct4SbNumber',
                        reportAct4SbNumber,
                    )
                } else if (
                    !reportAct4SbNumber &&
                    sessionStorage.getItem('reportAct4SbNumber')
                ) {
                    sessionStorage.removeItem('reportAct4SbNumber')
                }
                sessionStorage.setItem(
                    'originalMemberId',
                    this.$store.state.userModule.user.user.membernumber,
                )

                this.$store.dispatch('userModule/setActForMember', {
                    actForMemberId: actForMemberId,
                    actForMemberName: actForMemberName,
                    reportAct4SbNumber: reportAct4SbNumber,
                })
            }

            window.setTimeout(() => {
                dispatchEvent('actForMemberChanged')
            }, 250)

            this.$store.dispatch('navModule/closeProfileMenu')

            this.removeBackdropAndClickHandler()

            // Redirect to homepage on member change (both cases?)
            if (redirectOnChange) {
                window.setTimeout(() => {
                    if (location.pathname !== '/') {
                        location.href = '/'
                    }
                }, 500)
            }
        },
        resetOriginalMember(redirectOnChange = true) {
            sessionStorage.removeItem('actForMemberId')
            sessionStorage.removeItem('actForMemberName')
            sessionStorage.removeItem('originalMemberId')
            sessionStorage.removeItem('reportAct4SbNumber')

            this.$store.dispatch('userModule/setActForMember', {
                actForMemberId: null,
                actForMemberName: '',
            })

            // Redirect to homepage on member change
            if (redirectOnChange) {
                window.setTimeout(() => {
                    location.href = '/'
                }, 500)
            }
        },
        toggleProfileMenu() {
            CrefoUI.Header.closeBackdropAndSubmenues() // ensure menus are closed
            this.$store.dispatch('navModule/closeAllNavMenus')

            if (!this.isOpen) {
                if (!document.body.classList.contains('backdrop-active')) {
                    document.body.classList.add('backdrop-active')
                }

                this.$store.dispatch('navModule/openProfileMenu')

                // close menu on backdrop click
                document.body.addEventListener(
                    'click',
                    this.backdropHandler.bind(this),
                    false,
                )
            } else {
                this.$store.dispatch('navModule/closeAllNavMenus')
                this.removeBackdropAndClickHandler()
            }
        },
        toggleMemberstructureNavigation() {
            if (this.memberstructureNavigationOpen) {
                this.memberstructureNavigationOptionsSearch = ''
            }
            this.memberstructureNavigationOpen =
                !this.memberstructureNavigationOpen
        },
        getUserMemberdata() {
            this.$store.dispatch('userModule/getMemberdata')
        },
        getMemberstructure() {
            if (this.userHasRole('CR-CP.UNTERNEHMENSNAVIGATION')) {
                const actForMemberId = sessionStorage.getItem('actForMemberId')
                const originalMemberId =
                    this.$store.state.userModule.user.user.membernumber
                this.$store.watch(
                    (state) => state.userModule.user.user.memberStructure,
                    (newProps, oldProps) => {
                        if (newProps) {
                            let tempMemberId = originalMemberId
                            if (
                                actForMemberId &&
                                actForMemberId !== originalMemberId
                            ) {
                                tempMemberId = actForMemberId
                            } else if (
                                actForMemberId &&
                                actForMemberId === originalMemberId
                            ) {
                                this.resetOriginalMember(false)
                            }

                            if (
                                newProps &&
                                newProps.mainMember &&
                                newProps.mainMember.memberId &&
                                newProps.mainMember.memberId === tempMemberId
                            ) {
                                this.$store.dispatch(
                                    'userModule/setMemberNameAndId',
                                    {
                                        memberId: newProps.mainMember.memberId,
                                        memberName:
                                            newProps.mainMember.displayName,
                                    },
                                )
                                if (
                                    actForMemberId &&
                                    actForMemberId !== originalMemberId
                                ) {
                                    this.setActForMember(
                                        tempMemberId,
                                        newProps.mainMember.displayName,
                                        false,
                                        newProps.mainMember.reportAct4SbNumber,
                                    )
                                }
                            } else {
                                for (const i in newProps.subMembers) {
                                    if (
                                        newProps.subMembers[i].memberId ===
                                        tempMemberId
                                    ) {
                                        this.$store.dispatch(
                                            'userModule/setMemberNameAndId',
                                            {
                                                memberId:
                                                    newProps.subMembers[i]
                                                        .memberId,
                                                memberName:
                                                    newProps.subMembers[i]
                                                        .displayName,
                                            },
                                        )
                                        if (
                                            actForMemberId &&
                                            actForMemberId !== originalMemberId
                                        ) {
                                            this.setActForMember(
                                                tempMemberId,
                                                newProps.subMembers[i]
                                                    .displayName,
                                                false,
                                                newProps.subMembers[i]
                                                    .reportAct4SbNumber,
                                            )
                                        }
                                        break
                                    }
                                }
                            }

                            if (this.checkIfMemberNavigationAllowed(newProps)) {
                                this.setMemberstructureNavigationOptions(
                                    newProps,
                                )
                                this.memberstructureNavigationAllowed = true
                            }
                        }
                    },
                )
                this.$store.dispatch('userModule/getMemberStructure')
            }
        },

        checkIfMemberNavigationAllowed(memberStructure) {
            const originalMemberId =
                this.$store.state.userModule.user.user.membernumber
            if (memberStructure) {
                if (
                    memberStructure.mainMember &&
                    memberStructure.mainMember.memberId &&
                    memberStructure.mainMember.memberId === originalMemberId
                ) {
                    return true
                } else {
                    for (const i in memberStructure.subMembers) {
                        if (
                            memberStructure.subMembers[i].memberId ===
                            originalMemberId
                        ) {
                            return true
                        }
                    }
                    return false
                }
            } else {
                return false
            }
        },
        setMemberstructureNavigationOptions(memberStructure) {
            this.memberstructureNavigationOptions = []

            if (
                memberStructure &&
                memberStructure.mainMember &&
                memberStructure.memberId !== ''
            ) {
                this.memberstructureNavigationOptions.push({
                    displayName: memberStructure.mainMember.displayName,
                    address: memberStructure.mainMember.address,
                    memberId: memberStructure.mainMember.memberId,
                    reportAct4SbNumber:
                        memberStructure.mainMember.reportAct4SbNumber,
                    isMainMember: true,
                })
            }

            if (
                memberStructure &&
                memberStructure.subMembers &&
                memberStructure.subMembers.length > 0
            ) {
                for (const i in memberStructure.subMembers) {
                    this.memberstructureNavigationOptions.push({
                        displayName: memberStructure.subMembers[i].displayName,
                        address: memberStructure.subMembers[i].address,
                        memberId: memberStructure.subMembers[i].memberId,
                        reportAct4SbNumber:
                            memberStructure.subMembers[i].reportAct4SbNumber,
                        isMainMember: false,
                    })
                }
            }
        },
        userHasRole(roleName) {
            return this.$store.state.userModule.user.user.user_roles.includes(
                roleName,
            )
        },
        login() {
            window.keycloakStatusChecked = false

            const keycloak = new Keycloak(KC_CONF)

            let locale = 'de'
            if (document.cookie.indexOf('CAS_PREFERRED_LANGUAGE') !== -1) {
                const langCode = document.cookie
                    .split('CAS_PREFERRED_LANGUAGE=')[1]
                    .split(';')[0]
                locale = langCode.split('_')[0]
            }
            keycloak
                .init({
                    pkceMethod: 'S256',
                    onLoad: 'login-required',
                    locale,
                })
                .then((auth) => {
                    if (!auth) {
                        window.keycloakStatusChecked = true
                    } else {
                        this.onAuthenticated(keycloak)
                    }
                })
                .catch(() => {
                    window.keycloakStatusChecked = true
                })
        },
        logout() {
            window.keycloakStatusChecked = false
            clearAllStorages()

            dispatchEvent('loggedOut')

            const keycloakExists = setInterval(() => {
                if (
                    window.keycloak &&
                    window.keycloak.logout &&
                    typeof window.keycloak.logout === 'function'
                ) {
                    clearInterval(keycloakExists)
                    window.keycloak.logout({
                        redirectUri: getLogoutUrl(),
                    })
                    window.setTimeout(() => {
                        window.keycloakStatusChecked = true
                    }, 1000)
                }
            }, 200)
        },
        switchContext(data) {
            const memberStructure =
                this.$store.state.userModule.user.user.memberStructure
            const originalMemberId =
                this.$store.state.userModule.user.user.memberId

            if (memberStructure) {
                if (data.actForMemberId === originalMemberId) {
                    this.resetOriginalMember(false)
                } else {
                    if (
                        memberStructure.mainMember?.memberId ===
                            data.actForMemberId &&
                        memberStructure.mainMember?.navigationSelected
                    ) {
                        this.setActForMember(
                            data.actForMemberId,
                            memberStructure.mainMember.displayName,
                            false,
                            memberStructure.mainMember.reportAct4SbNumber,
                        )
                    } else {
                        memberStructure?.subMembers?.forEach((subMember) => {
                            if (
                                subMember.memberId === data.actForMemberId &&
                                subMember.navigationSelected
                            ) {
                                this.setActForMember(
                                    data.actForMemberId,
                                    subMember.displayName,
                                    false,
                                    subMember.reportAct4SbNumber,
                                )
                            }
                        })
                    }
                }
            }
        },
    },
    watch: {
        memberstructureNavigationOpen(val, oldVal) {
            // We don't want the user to accidentally scroll down the page.
            // If we would't do this, the menu would be to hidden because
            // of the scroll behavior of the header.
            if (!val) {
                document.body.classList.remove('prevent-scroll')
            } else {
                document.body.classList.add('prevent-scroll')
            }
        },
    },
    beforeCreate() {
        window.addEventListener(
            'switchContext',
            (e) => {
                this.switchContext(e.detail)
            },
            false,
        )
    },
    mounted() {
        if (this.$store.state.userModule.user.authenticated) {
            this.initData()
        } else {
            this.$store.watch(
                (state) => state.userModule.user.authenticated,
                (newProps, oldProps) => {
                    if (newProps) {
                        this.initData()
                    }
                },
            )
        }

        sessionStorage.setItem('skipActForMemberHeader', true)

        this.$store.watch(
            (state) => state.navModule.nav.profileMenuOpen,
            (newProps, oldProps) => {
                this.isOpen = newProps
                if (!newProps) {
                    this.memberstructureNavigationOpen = false
                    this.memberstructureNavigationOptionsSearch = ''
                }
            },
        )
    },
}
</script>

<style scoped lang="less">
.flex-header {
    .profile-menu {
        border-left: 1px solid #edece5;
        border-right: 1px solid #edece5;
        padding: 0 13px;
        align-self: stretch;
        display: flex;
        align-items: center;
        position: relative;

        .profile-menu-opener {
            i {
                vertical-align: middle;
            }

            .company-logo {
                border: 1px solid #edece5;
                padding: 12px 8px 16px;

                i {
                    font-size: 32px;
                }
            }

            .company-name {
                margin: 0 15px;
            }

            .open-close {
                i {
                    display: inline-block;
                    margin-top: -2px;
                    transition: transform 0.3s ease-in-out;
                }
            }
        }

        .profile-menu--slide {
            position: absolute;
            display: none;
            min-height: 50px;
            padding: 30px;
            border: 1px solid #edece5;

            .profile-picture-holder {
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: flex-start;
            }

            .company-logo {
                margin-bottom: 25px;

                i {
                    border: 1px solid #edece5;
                    padding: 20px;
                    font-size: 40px;
                    display: inline-block;
                }
            }

            .company-name {
                font-size: 18px;
                margin-bottom: 10px;
                display: inline-block;
            }

            .user,
            .settings {
                text-align: left;
                padding-bottom: 15px;
                border-bottom: 1px solid #edece5;
                margin-bottom: 15px;
            }

            .settings {
                position: relative;
            }

            .company {
                padding-bottom: 15px;
                border-bottom: 1px solid #edece5;
                margin-bottom: 15px;

                .edit {
                    position: relative;
                    padding-top: 15px;
                    margin-bottom: 10px;
                }
                a.edit {
                    position: absolute;
                    right: 15px;
                    top: -2px;
                }
            }

            .user-name {
                font-size: 14px;
                display: inline-block;
                width: 219px;

                & > span {
                    position: relative;
                    display: inline-block;
                    width: 100%;

                    &:first-of-type {
                        margin-bottom: 5px;
                    }
                }

                a {
                    display: inline-block;
                    width: 100%;
                    padding-right: 35px;
                }

                a.edit {
                    display: inline;
                    padding-right: 0;
                    width: auto;
                    position: absolute;
                    right: 15px;
                    top: -5px;
                }
            }

            .settings-link {
                font-size: 14px;
                display: inline-block;
                width: 219px;

                & > span {
                    position: relative;
                    display: inline-block;
                    width: 100%;

                    &:first-of-type {
                        margin-bottom: 0;
                    }
                }

                a {
                    display: inline-block;
                    width: 100%;
                    padding-right: 35px;
                }

                a.edit {
                    display: inline;
                    padding-right: 0;
                    width: auto;
                    position: absolute;
                    right: 15px;
                    top: -5px;
                }
            }

            .profile-picture {
                border: 1px solid #edece5;
                border-radius: 50%;
                padding: 10px;
                font-size: 32px;
                display: inline-block;
                margin-right: 15px;
            }
        }

        &.active {
            .profile-menu-opener {
                .open-close {
                    i {
                        transform: rotate(180deg);
                    }
                }
            }

            .profile-menu--slide {
                display: block;
                top: 100%;
                right: -1px;
                width: 350px;
                bottom: auto;
                background: #fff;
                z-index: 200;
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .profile-menu {
        display: none !important;
    }
}
@media only screen and (max-width: 1199px) {
    .flex-header .profile-menu {
        max-width: 260px;
    }
    .flex-header .profile-menu .profile-menu-opener .company-name {
        max-width: 125px;
        display: inline-block;
        text-overflow: ellipsis;
        vertical-align: middle;
    }
}

.login-link {
    & > span {
        display: inline-block;
        vertical-align: top;
        line-height: 23px;
        margin-right: 10px;
    }

    i::before {
        transition: color 0.15s ease-in-out;
    }

    &:hover {
        i::before {
            color: #004884 !important;
        }
    }
}

.btn-logout {
    & > span {
        margin-right: 15px;

        &:after {
            font-family: crefo-ui-icons !important;
            speak: none;
            font-style: normal;
            font-weight: 400;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            background-image: none !important;
            text-shadow: none !important;
            content: '\e91e';
            font-size: 21px;
            padding-left: 10px;
            top: 50%;
            position: absolute;
            transform: translateY(-50%);
            right: 15px;
        }
    }
}

.memberstructure-navigation {
    padding-top: 15px;
    position: relative;

    .btn-memberstructure {
        position: relative;
        z-index: 2;

        i {
            vertical-align: middle;
        }
        .open-close {
            i {
                display: inline-block;
                transition: transform 0.3s ease-in-out;
                font-size: 21px;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    &--list {
        position: absolute;
        top: 69px;
        width: 100%;
        height: 224px;
        border: 1px solid #009ee2;
        background: #fff;
        z-index: 1;
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.15s ease-out;
        text-align: left;

        &:before {
            font-family: crefo-ui-icons !important;
            speak: none;
            font-style: normal;
            font-weight: 400;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            background-image: none !important;
            text-shadow: none !important;
            content: '\e912';
            font-size: 21px;
            top: 17px;
            position: absolute;
            right: 15px;
            z-index: 3;
            color: #b5b5b5;
        }

        &--search {
            width: 100%;
            border: none;
            border-bottom: 1px solid #b5b5b5;
            height: 56px;
            position: absolute;
            outline: none;
            font-size: 15px;
            font-weight: normal;
            line-height: 26px;
            padding: 1rem 1.2rem;
            color: #4c4c4c;
        }

        &--results {
            overflow-x: scroll;
            max-height: calc(100% - 56px);
            position: absolute;
            top: 56px;
            width: 100%;

            p {
                min-height: 56px;
                font-size: 14px;
                font-weight: normal;
                line-height: 21px;
                padding: 1rem 1.2rem;
                color: #4c4c4c;
                margin: 0;
                border-bottom: 1px solid #b5b5b5;

                &:hover {
                    color: #fff;
                    background: #009ee2;
                    cursor: pointer;
                }
            }
        }
    }

    &.active {
        .btn-memberstructure {
            .open-close {
                i {
                    transform: translateY(-50%) rotate(180deg);
                }
            }
        }

        .memberstructure-navigation--list {
            max-height: 224px;
            transition: max-height 0.25s ease-in;
        }
    }
}
</style>
