import Vuex from 'vuex'

import navModule from './module-nav'
import userModule from './module-user'
import featureModule from './module-feature'

export default new Vuex.Store({
    strict: true,
    modules: {
        navModule,
        userModule,
        featureModule,
    },
})
