export const KC_CONF = {
    url: import.meta.env.VITE_APP_KEYCLOAK_AMS_AUTH_URL_SECURED,
    realm: 'credref',
    clientId: 'CR-CP',
}

function getUserManagementSelfcareUrl() {
    return KC_CONF.url.replace('/auth', '') + '/benutzerverwaltung/konto'
}

export { getUserManagementSelfcareUrl }
