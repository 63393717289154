import { useNewInkassoFrontend } from '@/services'
import store from '@/store'

export const standardInkassoSubitems = [
    {
        translateKey: 'mainmenu.debtcollection.subitem.withcrefo',
        dataQa: 'submenuItem-116',
        show: true,
        isRouterLink: true,
        href: '/portal/inkasso-cti/inkasso-mit-creditreform',
    },
    {
        translateKey: 'mainmenu.debtcollection.subitem.viaupload',
        dataQa: 'submenuItem-109',
        show: true,
        isRouterLink: true,
        href: '/portal/inkasso-cti/inkassoauftrag-per-upload',
    },
    {
        translateKey: 'mainmenu.debtcollection.subitem.viaform',
        dataQa: 'submenuItem-64',
        show: true,
        isRouterLink: true,
        href: '/portal/inkasso-cti/inkassoauftrag-per-formular',
    },
]

const $env = import.meta.env

const getOptionalGlaeubigerPortalMappingSubitems = (clz) => {
    if (
        !$env.VITE_APP_GLAUBIGER_PORTAL_LINK__CLZ_MAPPING ||
        !$env.VITE_APP_GLAUBIGER_PORTAL_LINK__CLZ_MAPPING.length
    ) {
        return undefined
    }

    let mapping = {}
    try {
        mapping = JSON.parse(
            atob($env.VITE_APP_GLAUBIGER_PORTAL_LINK__CLZ_MAPPING),
        )
    } catch {
        console.log('[Inkasso Menü] CLZ Mapping Gläubigerportal ungültig')
        return undefined
    }

    if (mapping[clz] && mapping[clz].length) {
        return [
            {
                translateKey:
                    'mainmenu.debtcollection.subitem.glaeubigerPortalForClz',
                dataQa: 'submenuItem-666_' + clz,
                show: true,
                isExternalLink: true,
                isRouterLink: false,
                href: mapping[clz],
            },
        ]
    }
    console.log('[Inkasso Menü] CLZ Mapping Gläubigerportal nicht für dies clz')
    return undefined
}

const userHasRole = (roleName) => {
    return store.state.userModule.user.user.user_roles.includes(roleName)
}

const getNewInkassoFrontendLinks = (clz) => {
    return [
        {
            translateKey: 'mainmenu.debtcollection.subitem.withcrefo',
            dataQa: 'submenuItem-ikaros_start_' + clz,
            show: true,
            isExternalLink: false,
            isRouterLink: true,
            href: '/portal/inkasso',
        },
        {
            translateKey: 'mainmenu.debtcollection.subitem.inkassoFiles',
            dataQa: 'submenuItem-ikaros_files_' + clz,
            show: true,
            isExternalLink: false,
            isRouterLink: true,
            href: '/portal/inkasso/uebersicht',
        },
        {
            translateKey: 'mainmenu.debtcollection.subitem.viascan',
            dataQa: 'submenuItem-ikaros_scan_' + clz,
            show: userHasRole('CR-CP.INKASSO-SCAN'),
            isExternalLink: false,
            isRouterLink: true,
            href: '/portal/inkasso-datenerkennung',
        },
        {
            translateKey: 'mainmenu.debtcollection.subitem.viaupload',
            dataQa: 'submenuItem-ikaros_upload_' + clz,
            show: true,
            isExternalLink: false,
            isRouterLink: true,
            href: '/portal/inkasso/inkassoauftrag-per-upload',
        },
        {
            translateKey: 'mainmenu.debtcollection.subitem.viaform',
            dataQa: 'submenuItem-ikaros_formular_' + clz,
            show: true,
            isExternalLink: false,
            isRouterLink: true,
            href: '/portal/inkasso/inkassoauftrag-per-formular',
        },
    ]
}

/** Returns Promise with individual sub menu items for the main/mobile menu.
 *
 * @param userClz of current user
 * @returns "Promise<[ <sub menu items> ]>"
 */
export const getCustomInkassoLinksForClz = (userClz) => {
    if (!userClz || typeof userClz === 'undefined') {
        // Wenn Umgebungsvariablen nicht geladen oder die Benutzer CLZ nicht ermittelt werden kann,
        // wird das CTI Inkasso als Standard im Menü angezeigt
        return Promise.resolve(standardInkassoSubitems)
    }

    const glaubigerPortalMapping =
        getOptionalGlaeubigerPortalMappingSubitems(userClz)
    if (glaubigerPortalMapping) {
        // wenn spezial, dann immer spezial
        return Promise.resolve(glaubigerPortalMapping)
    }

    if (
        $env.VITE_APP_FEATURE_IKAROS_ACTIVE &&
        $env.VITE_APP_FEATURE_IKAROS_ACTIVE !== 'false'
    ) {
        return useNewInkassoFrontend().then((newInkasso) => {
            if (newInkasso) {
                return getNewInkassoFrontendLinks(userClz)
            }
            return standardInkassoSubitems
        })
    }
    return Promise.resolve(standardInkassoSubitems)
}
